<template lang="html">
  <div class="bg">
    <div class="theme p2 popup">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupWindow'
}
</script>

<style lang="css" scoped>
.bg {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.popup {
  max-width: 800px;
}
</style>
