import en from './en'
import fr from './fr'
import { i18n } from 'arketiks-tools'

export default {
  locale: localStorage.getItem('lang') || (window.navigator.language.startsWith('fr') ? 'fr' : 'en'),
  messages: {
    en: { ...i18n.en, ...en },
    fr: { ...i18n.fr, ...fr }
  }
}
