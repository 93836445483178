import { defineStore } from 'pinia'
import { getFirestore, doc, setDoc, query, where, orderBy, addDoc, deleteDoc, getDocs, collection, getDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { timestampToMinutes } from '../utils'
import { getScore } from '@/utils/results'
import router from '@/router'
import { api, useUIStore } from 'arketiks-tools'

const db = getFirestore()
const functions = getFunctions(undefined, 'europe-west6')

export const useExamsStore = defineStore('exams', {
  state: () => ({
    exams: [],
    currentExam: null,
    session: null,
    results: []
  }),
  actions: {
    async generateQuestions (params) {
      try {
        params.text = params.text.replace(/\s{2,}/, ' ').split(' ').slice(0, 1000).join(' ')
        const generateQuestions = httpsCallable(functions, 'generateQuestions')
        useUIStore().setLoading(true)
        const response = await generateQuestions(params)
        useUIStore().setLoading(false)
        return JSON.parse(response.data)
      } catch (e) {
        useUIStore().addDangerNotification(e.message)
      }
      useUIStore().setLoading(false)
    },
    async getExams (refresh) {
      if (this.exams.length && !refresh) return
      useUIStore().setLoading(true)
      const snapshots = await getDocs(query(collection(db, `orgs/${api.getOrganizationId()}/exams`),
        orderBy('createdAt', 'desc')))
      useUIStore().setLoading(false)
      const exams = []
      snapshots.forEach(doc => exams.push({ id: doc.id, data: doc.data() }))
      this.exams = exams
    },
    async setExam (exam) {
      try {
        useUIStore().setLoading(true)
        exam.data.updatedAt = Date.now()
        let ref
        if (exam.id) {
          ref = doc(db, `orgs/${api.getOrganizationId()}/exams/${exam.id}`)
        } else {
          ref = doc(collection(db, `orgs/${api.getOrganizationId()}/exams`))
        }
        useUIStore().setLoading(false)
        await setDoc(ref, exam.data)
        exam.id = ref.id
        this.updateExam(exam)
        useUIStore().addSuccessNotification('saved')
        return exam
      } catch (e) {
        useUIStore().addDangerNotification(e.message)
      }
      useUIStore().setLoading(false)
    },
    setSession (session) {
      this.session = session
    },
    updateExam (exam) {
      let examExist = false
      this.exams = this.exams.map(e => {
        if (e.id === exam.id) {
          examExist = true
          return exam
        }
        return e
      })
      if (!examExist) this.exams.push(exam)
    },
    async getExam (id) {
      if (this.currentExam?.id === id) return this.currentExam
      let exam = this.exams.find(e => e.id === id)
      this.results = []
      if (!exam) {
        useUIStore().setLoading(true)
        const snapshot = await getDoc(doc(db, `orgs/${api.getOrganizationId()}/exams`, id))
        useUIStore().setLoading(false)
        exam = { id: snapshot.id, data: snapshot.data() }
      }
      this.currentExam = exam
      return this.currentExam
    },
    async deleteExam (id) {
      useUIStore().setLoading(true)
      for (const result of this.results) {
        await deleteDoc(doc(db, `orgs/${api.getOrganizationId()}/results`, result.id))
      }
      await deleteDoc(doc(db, `orgs/${api.getOrganizationId()}/exams`, id))
      useUIStore().setLoading(false)
      this.exams = this.exams.filter(e => e.id !== id)
    },
    async getSession ({ email, examId }) {
      const getSession = httpsCallable(functions, 'getSession')
      useUIStore().setLoading(true)
      const response = await getSession({ email, examId, orgId: router.currentRoute.value.params.org })
      useUIStore().setLoading(false)
      if (response.data) {
        if (
          !response.data.result.data.startTime ||
          localStorage.getItem('credentials') === JSON.stringify({ email, examId })
        ) {
          this.session = { email, examId, ...response.data }
          localStorage.setItem('credentials', JSON.stringify({ email, examId }))
        } else {
          useUIStore().addDangerNotification('sessionStarted')
        }
      }
    },
    async getPublicSession () {
      const snapshot = await getDoc(doc(db, `orgs/${router.currentRoute.value.params.org}/exams`, router.currentRoute.value.params.examId))
      if (snapshot.exists() && snapshot.data().isOpen && snapshot.data().isPublic) {
        const session = {}
        session.exam = { id: snapshot.id, data: snapshot.data() }
        session.result = { data: {} }
        this.session = session
        return session
      }
    },
    async getPreview (examId) {
      if (!this.currentExam) {
        await this.getExam(examId)
      }
      this.session = { exam: this.currentExam, result: { data: {} } }
    },
    async setResult (result) {
      if (!result.data.email) return
      useUIStore().setLoading(true)
      try {
        await setDoc(doc(db, `orgs/${router.currentRoute.value.params.org}/results/${result.id}`), {
          ...result.data,
          updatedAt: Date.now()
        })
        this.results = this.results.map(r => r.id === result.id ? result : r)
      } catch (e) {
        useUIStore().addDangerNotification(e.message)
        throw new Error('The result couldn\'t be saved. Please check your connection.')
      }
      useUIStore().setLoading(false)
    },
    async getResult (resultId) {
      const snapshot = await getDoc(doc(db, `orgs/${api.getOrganizationId()}/results`, resultId))
      const data = snapshot.data()
      if (data.endTime) {
        data.duration = timestampToMinutes(data.endTime - data.startTime)
        if (data.endTime < 1683812799549) {
          for (const t of Object.keys(data.topics)) {
            for (const s of Object.keys(data.topics[t].situations)) {
              data.topics[t].situations[s].questions = data.topics[t].situations[s].instructions
            }
          }
        }
      }
      return { id: snapshot.id, data }
    },
    async deleteResult (id) {
      useUIStore().setLoading(true)
      await deleteDoc(doc(db, `orgs/${api.getOrganizationId()}/results`, id))
      useUIStore().setLoading(false)
      this.results = this.results.filter(r => r.id !== id)
    },
    async getResults (examId) {
      if (this.results.length) return
      this.results = []

      const snapshots = await getDocs(query(collection(db, `orgs/${api.getOrganizationId()}/results`),
        where('examId', '==', examId)))

      const results = snapshots.docs.map(doc => {
        const data = doc.data()
        if (data.endTime) {
          data.duration = timestampToMinutes(data.endTime - data.startTime)
          if (data.endTime < 1683812799549) {
            for (const t of Object.keys(data.topics)) {
              for (const s of Object.keys(data.topics[t].situations)) {
                data.topics[t].situations[s].questions = data.topics[t].situations[s].instructions
              }
            }
          }
        }
        return { id: doc.id, data }
      }).sort((a, b) => a.data.firstname.localeCompare(b.data.firstname))
      this.results = results
    },
    async addResult (result) {
      useUIStore().setLoading(true)
      try {
        result.attempt = 0
        const snapshot = await addDoc(collection(db, `orgs/${api.getOrganizationId()}/results`), result)
        this.results = [{ id: snapshot.id, data: result }, ...this.results]
      } catch (e) {
        useUIStore().addDangerNotification(e.message)
      }
      useUIStore().setLoading(false)
    },
    async sendResult ({ exam, result }) {
      const sendEmail = httpsCallable(functions, 'sendEmail')
      if (result && result.data.answers) {
        try {
          const score = getScore(exam, result.data.answers)
          useUIStore().setLoading(true)
          const details = Object.keys(result.data.topics).map(i => {
            if (exam.data.topics[i].name) {
              const topicScore = getScore(exam, result.data.topics[i].answers)
              return `<div style="display:flex;border-bottom:1px solid #000;margin-top:10px"><div style="flex:auto">${exam.data.topics[i].name}</div> <div>${topicScore}%</div></div>`
            }
            return ''
          }).join('')
          const message = score >= exam.data.successScore
            ? exam.data.email.successMessage
            : exam.data.email.failureMessage
          const response = await sendEmail({
            to: result.data.email,
            subject: exam.data.email.subject,
            html: `
              <!DOCTYPE html>
              <html lang="en" dir="ltr">
                <body style="width:600px;margin:auto">
                  <h4 style="text-align:center">${exam.data.email.score.replace(/{{exam}}|_exam/, exam.data.name).replace(/{{score}}|_score/, score)}</h4>
                  <p>${message}</p>
                  ${details}
                  <h6 style="text-align:center">Exam by VisionCompliance SA</h6>
                </body>
              </html>
              `
          })
          if (response.data && response.data.error) useUIStore().addDangerNotification(response.data.error.response)
          else useUIStore().addSuccessNotification('sent')
        } catch (e) {
          useUIStore().addDangerNotification(e.message)
        }
        useUIStore().setLoading(false)
      }
    },
    async sendDiplomas ({ results, exam }) {
      try {
        if (!exam.data.diplomaTitle) return
        const diplomas = results
          .filter(result => result.data.passed && !!result.data.firstname && !!result.data.lastname && !!result.data.birthdate)
          .map(result => {
            return {
              templateId: 'tzIyx4kkjfuSn4UXiltg',
              date: new Date().toISOString().substring(0, 10),
              resultId: result.id,
              email: result.data.email,
              firstname: result.data.firstname,
              lastname: result.data.lastname,
              birthdate: result.data.birthdate,
              title: exam.data.diplomaTitle
            }
          })
        if (!diplomas.length) return
        let notes = 'Exam ' + exam.data.name
        if (diplomas.length === 1) {
          notes += ` ${diplomas[0].firstname} ${diplomas[0].lastname} ${diplomas[0].email}`
        }
        const generateDiploma = httpsCallable(functions, 'generateDiploma')
        const result = await generateDiploma({
          exam: exam.data.name,
          diplomas,
          notes
        })
        if (result.data.error) {
          useUIStore().addDangerNotification(result.data.error)
        } else {
          useUIStore().addSuccessNotification('Done')
        }
      } catch (e) {
        useUIStore().addDangerNotification(e.message)
      }
    }
  }
})
