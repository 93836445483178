<template lang="html">
  <div class="end">
    <p v-if="showScore">
      Score {{ getScore(session.exam, session.result.data.answers) }}%
    </p>
    <div v-if="canRetry">
      <p>{{ $t('session.retryMessage', { attempts: session.exam.data.maxAttempt - session.result.data.attempt }) }}</p>
      <button @click="$router.push({ name: 'OrgLogin', params: { examId: session.exam.id } })">
        {{ $t('session.retry') }}
      </button>
    </div>
    <p v-if="session.exam.data.showCorrection">
      <button @click="$router.push({ name: 'Session' })">
        {{ $t('session.correction') }}
      </button>
    </p>
    <p>
      {{ $t('session.thanks') }}
    </p>
  </div>
</template>

<script type="text/javascript">
import { mapState, mapActions } from 'pinia'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useExamsStore } from '../stores/exams'
import { getScore } from '@/utils/results'

const functions = getFunctions(undefined, 'europe-west6')

export default {
  name: 'SessionEnd',
  data () {
    return {
      showScore: false,
      canRetry: false
    }
  },
  computed: {
    ...mapState(useExamsStore, ['session'])
  },
  async created () {
    if (this.session.result.data.email) {
      this.canRetry = this.session.exam.data.maxAttempt &&
        this.session.result.data.attempt < this.session.exam.data.maxAttempt &&
        this.getScore(this.session.exam, this.session.result.data.answers) < this.session.exam.data.successScore
      if (this.canRetry) {
        this.resetResult()
      } else this.notify()
    } else {
      this.showScore = true
    }
    window.parent.postMessage('done', '*')
  },
  methods: {
    ...mapActions(useExamsStore, [
      'sendResult',
      'setResult',
      'sendDiplomas'
    ]),
    getScore,
    async notify () {
      if (this.session.result.data.email) {
        if (this.session.exam.data.autoSendResult) {
          await this.sendResult(this.session)
        } else await this.sendEmail()
        if (this.session.exam.data.autoSendDiploma) {
          this.session.result.data.passed = this.getScore(this.session.exam, this.session.result.data.answers) >= this.session.exam.data.successScore
          this.sendDiplomas({ results: [this.session.result], exam: this.session.exam })
        }
      }
      if (this.session.exam.data.notify) await this.sendNotification()
    },
    async resetResult () {
      await this.setResult({
        id: this.session.result.id,
        data: {
          examId: this.session.result.data.examId,
          firstname: this.session.result.data.firstname,
          lastname: this.session.result.data.lastname,
          email: this.session.result.data.email,
          birthdate: this.session.result.data.birthdate,
          attempt: this.session.result.data.attempt
        }
      })
    },
    sendEmail () {
      const sendEmail = httpsCallable(functions, 'sendEmail')
      return sendEmail({
        to: this.session.result.data.email,
        subject: 'Examen ' + this.session.exam.data.name,
        html: `
          <!DOCTYPE html>
          <html lang="en" dir="ltr">
            <body style="width:600px;margin:auto">
              <h4 style="text-align:center">Examen ${this.session.exam.data.name}</h4>
              ${this.$t('session.email')}
              <h6 style="text-align:center">VisionCompliance SA</h6>
            </body>
          </html>
          `
      })
    },
    sendNotification () {
      const sendEmail = httpsCallable(functions, 'sendEmail')
      return sendEmail({
        to: this.session.exam.data.notify,
        subject: `Examen ${this.session.exam.data.name} - ${this.session.result.data.email}`,
        html: `
          <!DOCTYPE html>
          <html lang="en" dir="ltr">
            <body style="width:600px;margin:auto">
              <h4 style="text-align:center">Examen ${this.session.exam.data.name}</h4>
              <p>${this.session.exam.data.name} has been done by ${this.session.result.data.email}.</p>
              <p>Score: ${this.getScore(this.session.exam, this.session.result.data.answers)}%.</p>
              <h6 style="text-align:center">VisionCompliance SA</h6>
            </body>
          </html>
          `
      })
    }
  }
}
</script>

<style scoped>
.end {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  max-width: 600px;
  margin: auto;
}

button {
  font-size: 1.5rem;
  padding: .75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
