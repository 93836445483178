import { defineStore } from 'pinia'
import { getFirestore, doc, setDoc, query, where, getDocs, collection, deleteDoc } from 'firebase/firestore'
import router from '@/router'
import { getAuth, signOut } from 'firebase/auth'
import { api, useUIStore } from 'arketiks-tools'

const db = getFirestore()

export const useAppStore = defineStore('app', {
  state: () => ({
    user: null,
    orgs: [],
    admins: []
  }),
  getters: {
    org: state => {
      const alias = router.currentRoute.value?.params.org || router.currentRoute.value.redirectedFrom?.params.org
      const org = state.orgs.find(o => o.alias === alias || o.id === alias)
      api.setOrganizationId(org?.id)
      return org
    }
  },
  actions: {
    logout () {
      this.user = null
      this.orgs = []
      signOut(getAuth()).then(() => {
        router.push('/')
      })
    },
    async getUser (authUser) {
      if (this.user) return this.user
      authUser = authUser || getAuth().currentUser
      if (authUser) {
        if (this.user && this.orgs) return this.user
        let user = await api.getUser(authUser)
        const alias = router.currentRoute.value.redirectedFrom?.params.org
        if (router.currentRoute.value.redirectedFrom?.name === 'organizations' || !user.orgs.length) {
          user = await api.updateOrgs(user)
        }

        if (user.orgs.length) {
          this.orgs = await api.getOrganizations(user)
          const alias = router.currentRoute.value.redirectedFrom?.params.org || user.defaultOrg
          const defaultOrg = this.orgs.find(o => o.alias === alias || o.id === alias) || this.orgs[0]
          api.setOrganizationId(defaultOrg?.id)
          user = await api.getOrgUser(user)
          this.user = user
          if (router.currentRoute.value.name === 'Login') {
            await router.replace(router.currentRoute.value.redirectedFrom || { name: 'Exams', params: { org: defaultOrg.alias } })
          }
        } else {
          await router.push({ name: 'noOrg', query: { org: alias } })
        }
      }
    },
    async addAdmin (email) {
      if (this.admins.some(a => a.email === email)) return
      useUIStore().setLoading(true)
      const user = {
        email,
        role: 'admin'
      }
      await setDoc(doc(db, `orgs/${this.org.id}/orgUsers`, email), user)
      this.admins.push({ id: email, data: user })
      useUIStore().setLoading(false)
    },
    async removeAdmin (admin) {
      useUIStore().setLoading(true)
      await deleteDoc(doc(db, `orgs/${this.org.id}/orgUsers`, admin.id))
      this.admins = this.admins.filter(a => a.id !== admin.id)
      useUIStore().setLoading(false)
    },
    async getAdmins () {
      useUIStore().setLoading(true)
      const snapshots = await getDocs(query(collection(db, `orgs/${this.org.id}/orgUsers`),
        where('role', '==', 'admin')))
      const admins = []
      snapshots.forEach(doc => admins.push({ id: doc.id, data: doc.data() }))
      this.admins = admins
      useUIStore().setLoading(false)
    }
  }
})
