<template lang="html">
  <div
    :id="'context' + id"
  >
    <h4>{{ $t('session.context') }}</h4>
    <div class="context">
      <div
        :id="'text' + id"
        class="text"
        v-html="context"
      />
      <canvas
        :id="'canvas' + id"
        class="theme canvas"
        height="0"
      />
      <div
        class="canvas top"
        @mousedown="startDrawing"
        @touchstart="startDrawing"
        @mousemove="draw"
        @touchmove="draw"
        @mouseup="stopDrawing"
        @touchend="stopDrawing"
        @mouseleave="stopDrawing"
      />
    </div>
    <div class="colors">
      <div
        v-for="c in colors"
        :key="c"
        class="color"
        :style="`background-color:${c};${c === color ? 'box-shadow: none;border-color:#ddd' : ''}`"
        @click="color = c"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContextView',
  props: {
    id: {
      type: String,
      default: ''
    },
    context: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      ctx: null,
      lastPos: null,
      offset: null,
      ro: null,
      color: '#f96',
      colors: [
        '#fff',
        '#444',
        '#2e6',
        '#f96',
        '#f44'
      ],
      fontsize: 18,
      canvasTop: 10
    }
  },
  mounted () {
    this.ro = new ResizeObserver(this.resizeCanvas)
      .observe(document.getElementById('context' + this.id))
  },
  unmounted () {
    delete this.ro
  },
  methods: {
    resizeCanvas () {
      const view = document.getElementById('text' + this.id)
      if (!view) return
      this.fontsize = parseFloat(
        window.getComputedStyle(view, null)
          .getPropertyValue('font-size').replace('px', '')
      )
      this.canvasTop = this.fontsize / 2 + 0.125 * this.fontsize
      this.fontsize += Math.round(0.125 * this.fontsize)

      const canvas = document.getElementById('canvas' + this.id)
      canvas.width = view.parentElement.scrollWidth + 10
      canvas.height = view.parentElement.scrollHeight
      this.ctx = canvas.getContext('2d')
      this.ctx.fillStyle = window.getComputedStyle(canvas, null).getPropertyValue('background-color')
      this.ctx.lineWidth = this.fontsize
      this.ctx.fillRect(0, 0, canvas.width, canvas.height)

      this.offset = {
        x: (window.outerWidth - view.clientWidth) / 2 - this.ctx.lineWidth / 2,
        y: this.ctx.lineWidth / 2
      }
    },
    getPosition (event) {
      const pos = {}
      if (event.touches) {
        pos.x = event.touches[0].clientX - this.offset.x
        pos.y = event.touches[0].clientY - this.offset.y +
          event.target.parentElement.parentElement.scrollTop
      }
      pos.x = event.offsetX
      pos.y = event.offsetY

      pos.y = Math.floor(pos.y / this.fontsize) * this.fontsize + this.canvasTop
      return pos
    },
    startDrawing (event) {
      this.lastPos = this.getPosition(event)
      this.ctx.strokeStyle = this.color
    },
    stopDrawing (event) {
      this.lastPos = null
    },
    draw (event) {
      if (!event.touches || event.touches.length === 1) event.preventDefault()
      else this.lastPos = null
      if (!this.lastPos) return
      const pos = this.getPosition(event)
      if (pos.y === this.lastPos.y) {
        this.ctx.beginPath()
        this.ctx.moveTo(this.lastPos.x, this.lastPos.y)
        this.ctx.lineTo(pos.x, pos.y)
        this.ctx.stroke()
      }

      this.lastPos = pos
    }
  }
}
</script>

<style lang="css" scoped>
.context {
  position: relative;
}

h4 {
  margin-top: 0rem;
  display: flex;
  align-items: center;
}

.colors {
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
}

.color {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  margin-left: 1rem;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 1px solid transparent;
}

.text {
  line-height: 1.125;
  white-space: pre-line;
}

.canvas {
  position: absolute;
  top: 0;
  left: -5px;
  right: 0;
  bottom: 0;
  cursor: url('@/assets/pen.png'), pointer;
  opacity: 0.6;
}
canvas {
  z-index: -1;
}
.top {
  z-index: 1;
}
</style>
