<template>
  <div id="app">
    <nav
      v-if="!$route.name || !$route.name.startsWith('Session')"
      class="theme m0"
    >
      <label
        class="toggle"
        for="nav-toggle"
      >
        <div />
        <div />
      </label>
      <input
        id="nav-toggle"
        type="checkbox"
      >
      <div class="logo m ml2" />
      <div class="content">
        <router-link
          v-if="user"
          :to="{ name: $route.params.org ? 'Exams' : 'Login' }"
        >
          {{ $t('exam.exams') }}
        </router-link>
        <router-link
          v-if="currentExam"
          :to="{ name: 'Exam', params: { examId: currentExam.id } }"
        >
          {{ currentExam.data.name }}
        </router-link>
        <div class="dropdown ml">
          <button>{{ $t('languages.' + $i18n.locale) }}</button>
          <div>
            <button
              v-for="lang in ['en', 'fr'].filter(l => l !== $i18n.locale)"
              :key="lang"
              @click="setLanguage(lang)"
            >
              {{ $i18n.messages[lang].languages[lang] }}
            </button>
          </div>
        </div>
        <div
          v-if="user && $route.params.org"
          class="dropdown right"
        >
          <button>{{ org?.name }}</button>
          <div>
            <router-link
              v-for="org in orgs"
              :key="org.id"
              :to="{ name: 'Exams', params: { org: org.alias } }"
            >
              {{ org.name }}
            </router-link>
            <button @click="logout">
              {{ $t('logout') }}
            </button>
          </div>
        </div>
      </div>
    </nav>
    <router-view v-slot="{ Component }">
      <keep-alive :include="['Exams', 'Session']">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <TheNotifications />
    <TheLoading />
  </div>
</template>

<script type="text/javascript">
import { TheLoading, TheNotifications, useUIStore } from 'arketiks-tools'
import { mapState, mapActions } from 'pinia'
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from 'firebase/auth'
import { useAppStore } from './stores/app'
import { useExamsStore } from './stores/exams'

export default {
  name: 'App',
  components: {
    TheLoading,
    TheNotifications
  },
  computed: {
    ...mapState(useAppStore, [
      'user',
      'orgs',
      'org'
    ]),
    ...mapState(useExamsStore, [
      'currentExam'
    ])
  },
  watch: {
    $route () {
      if (this.$route.query.lang) {
        this.setLanguage(this.$route.query.lang)
      }
    }
  },
  created () {
    this.checkSignIn()
  },
  methods: {
    ...mapActions(useAppStore, [
      'getUser',
      'clearUser',
      'logout'
    ]),
    ...mapActions(useUIStore, [
      'setLoading'
    ]),
    checkSignIn () {
      this.checkUser()
      if (isSignInWithEmailLink(getAuth(), window.location.href)) {
        this.setLoading(true)
        let email = window.localStorage.getItem('emailForSignIn')
        if (!email) {
          email = window.prompt('Please provide your email for confirmation')
        }
        signInWithEmailLink(getAuth(), email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem('emailForSignIn')
            this.setLoading(false)
          })
          .catch(() => {
            this.setLoading(false)
          })
      }
    },
    checkUser () {
      onAuthStateChanged(getAuth(), async (user) => {
        if (user) {
          await this.getUser(user)
        }
      })
    },
    setLanguage (lang) {
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
    }
  }
}
</script>
<style>
html, body {
  padding: 0;
  margin: 0;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  background-image: url(./assets/logo.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin: 0.25rem 0.5rem;
  border-radius: 3px;
}

.home {
  border: none;
  font-size: 1.5rem;
  padding: 0;
}

.router-link-exact-active {
  font-weight: bold;
}
</style>
