export default {
  exams: 'Exams',
  add: 'Add',
  delete: 'Delete',
  cancel: 'Cancel',
  close: 'Close',
  confirm: 'Confirm',
  confirming: 'Are you sure?',
  firstname: 'Firstname',
  lastname: 'Lastname',
  email: 'Email',
  birthdate: 'Birthdate',
  logout: 'Logout',
  newExam: 'New exam',
  opened: 'Opened',
  closed: 'Closed',
  public: 'Public',
  private: 'Private',
  sortBy: 'Sort by',
  name: 'Name',
  createdAt: 'Creation',
  updatedAt: 'Update',
  send: 'Send',
  login: {
    welcome: 'Welcome',
    login: 'Login',
    checkMailbox: 'Look at your mailbox and click on the link you received.',
    loading: 'Chargement',
    noaccess: 'This session is not accessible',
    noOrg: 'No organization was found for this email. Please contact <a href="mailto:support@visioncompliance.ch">support@visioncompliance.ch</a> to get an access.'
  },
  admins: {
    admins: 'Administrators',
    addAdmin: 'Add an administrator'
  },
  session: {
    welcome: 'Welcome to the exam',
    explanation: 'Multiple situations will be presented to you. Some have a context to know more about the situation and where you can highlight the text. You can also take some notes to help you with each situation. For every situation you will be asked to tell us if some answers are true or false. If your answer is incorrect you will lose points but if you don\'t know the answer no point will be lost.',
    nameConfirmation: 'Please confirm that your name is',
    birthConfirmation: 'and you were born on',
    confirmation: 'Confirm & Start',
    true: 'True',
    false: 'False',
    null: 'I don\'t know',
    context: 'Context',
    notes: 'Notes',
    notesDetails: 'Personal notes',
    close: 'Close',
    end: 'End',
    questions: 'question | Questions',
    unanswered: 'not answered',
    timeout: 'Time remaining',
    finish: 'Finish',
    answers: 'Answers',
    notDone: 'You did not answer',
    finishConfirmation: 'Do you want to send your answers and finish the exam?',
    cancel: 'No, cancel',
    confirm: 'Yes, finish',
    thanks: 'Thank you for participating.',
    email: '<p> We confirm that the data of your exam is well saved at VisionCompliance. You will receive the results of your exam shortly. </p> <p> Thank you for your participation. </p> ',
    retryMessage: `We are sorry to inform you that you did not pass this exam.
You have the possibility to take the test {attempts} more time(s).
We encourage you to review the associated training before you try again.`,
    retry: 'Retry',
    correction: 'Correction'
  },
  notifications: {
    saved: 'Saved',
    sent: 'Sent',
    sessionStarted: 'This session has already been started.'
  },
  newAttendants: {
    file: 'Example file',
    changeEmail: 'Please change the email',
    addManualy: 'Manually add a new attendant',
    import: 'Import attendants',
    importCSV: 'Import attendants from a .csv file'
  },
  exam: {
    preview: 'Preview',
    exams: 'Exams',
    link: 'Access link',
    publicLink: 'Public link',
    edit: 'Edit',
    feedback: 'Feedback',
    duplicate: 'Duplicate',
    notify: 'Email to receive a notification when this exam has been done by someone.',
    diplomaTitle: 'Diploma title',
    startDate: 'Start date',
    endDate: 'End date',
    addAttendant: 'Add attendants',
    filter: 'Filter',
    stats: '% have succeeded with an average of {averageScore}% in {averageTime} minutes.',
    editWarning: 'You can\'t edit the exam after someone started it.',
    notStarted: 'Hasn\'t started',
    sendResults: 'Send results',
    sendDiplomas: 'Send diplomas',
    diplomaConfirmation: 'Make sure all the diploma fields are correct before sending the diplomas. Are you sure that they are correct and you want to send the diplomas ?',
    autoSendResult: 'Automatically send results',
    autoSendDiploma: 'Automatically send diplomas',
    deleteConfirmation: 'Do you really want to delete this exam and all the results?',
    emailSubject: 'Exam result',
    emailScore: 'Your score for the exam _exam is _score%.',
    emailSuccess: 'We congratulate you on passing your exam. Soon, you will receive a link that will give you access to your diploma.',
    emailFailure: 'We are sorry, you failed this exam.'
  },
  editor: {
    editor: 'Editor',
    back: 'Back',
    export: 'Export',
    save: 'Save',
    name: 'Name',
    time: 'Time (minutes)',
    language: 'Language',
    maxAttempt: 'Attempt(s)',
    negativePoints: 'Negative points',
    introduction: 'Introduction',
    successScore: 'Success score',
    questionsToShow: 'Number of question',
    hideQuestions: 'Show subset of questions',
    isMCQ: 'Multiple choice questions',
    email: 'Result email',
    emailSubject: 'Subject',
    emailScore: 'Score',
    emailSuccess: 'Success message',
    emailFailure: 'Failure message',
    exam: 'Exam',
    remove: 'Remove',
    optional: 'Optional',
    topic: 'Topic',
    situation: 'Situation',
    context: 'Context',
    image: 'Image',
    notImage: 'This is not an image',
    question: 'Question',
    answer: 'Answer',
    addAnswer: 'Add an answer',
    addQuestion: 'Add an question',
    addSituation: 'Add a situation',
    addTopic: 'Add a topic',
    showCorrection: 'Show correction',
    generate: 'Generate',
    generationText: 'Source text (smaller than 1000 words)'
  },
  results: {
    status: 'Status',
    duration: 'Duration',
    date: 'Date',
    points: 'Points',
    score: 'Score',
    correct: 'Correct',
    unanswered: 'Unanswered',
    wrong: 'Wrong',
    reset: 'Reset'
  },
  languages: {
    en: 'English',
    fr: 'French'
  }
}
