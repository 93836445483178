import { initializeApp } from 'firebase/app'
import { initializeFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY || 'AIzaSyDkJpaSF3kh_zq15lrCEZJhythzdp2H_DA',
  authDomain: 'exam.visioncompliance.ch',
  databaseURL: 'https://exam-b68dd.firebaseio.com',
  projectId: 'exam-b68dd',
  storageBucket: 'exam-b68dd.appspot.com',
  messagingSenderId: '513033711681',
  appId: '1:513033711681:web:2b368019e92086d7a9d6c0'
}

const app = initializeApp(firebaseConfig)
initializeFirestore(app, { experimentalAutoDetectLongPolling: true })

if (process.env.NODE_ENV === 'development') {
  import('./firebase-emulate')
}
