export default {
  exams: 'Examens',
  add: 'Ajouter',
  delete: 'Supprimer',
  cancel: 'Annuler',
  close: 'Fermer',
  confirm: 'Confirmer',
  confirming: 'Êtes-vous sûr ?',
  firstname: 'Prénom',
  lastname: 'Nom',
  birthdate: 'Date de naissance',
  logout: 'Déconnexion',
  newExam: 'Nouvel examen',
  opened: 'Ouvert',
  closed: 'Fermé',
  public: 'Publique',
  private: 'Privé',
  sortBy: 'Tri par',
  name: 'Nom',
  createdAt: 'Création',
  updatedAt: 'Mise à jour',
  send: 'Envoyer',
  login: {
    welcome: 'Bienvenue',
    login: 'Connexion',
    checkMailbox: 'Regarder votre boite email et cliquer sur le lien que vous avez reçu.',
    loading: 'Chargement',
    noaccess: 'Cette session n\'est pas accessible',
    noOrg: 'Aucune organisation n\'a été trouvée pour cet email. Veuillez contacter <a href="mailto:support@visioncompliance.ch">support@visioncompliance.ch</a> pour avoir un accès.'
  },
  admins: {
    admins: 'Administrateurs',
    addAdmin: 'Ajouter un administrateur'
  },
  session: {
    welcome: 'Bienvenue à l\'examen ',
    explanation: 'Plusieurs situations vous seront présentées. Certaines ont un contexte pour en savoir plus sur la situation et où vous pouvez mettre en évidence le texte. Vous pouvez également prendre quelques notes pour vous aider pour chaque situation. Pour chaque situation, il vous sera demandé de nous dire si certaines réponses sont vraies ou fausses. Si votre réponse est incorrecte, vous perdrez des points mais si vous ne connaissez pas la réponse, aucun point ne sera perdu.',
    nameConfirmation: 'Veuillez confirmer que vous vous appelez ',
    birthConfirmation: ' et que vous êtes né le ',
    confirmation: 'Confirmer & Commencer',
    true: 'Vrai',
    false: 'Faux',
    null: 'Je ne sais pas',
    context: 'Contexte',
    notes: 'Notes',
    notesDetails: 'Notes personnelles',
    close: 'Fermer',
    end: 'Fin',
    questions: 'question | questions',
    unanswered: 'non répondu',
    timeout: 'Temps restant',
    finish: 'Terminer',
    answers: 'Reponses',
    notDone: 'Vous n\'avez pas répondu à ',
    finishConfirmation: 'Voulez-vous envoyer vos réponses et terminer l\'examen ?',
    cancel: 'Non, annuler',
    confirm: 'Oui, terminer',
    thanks: 'Merci d\'avoir participé.',
    email: '<p>Nous vous confirmons que les données de votre examen sont bien enregistrées chez VisionCompliance. Vous recevrez prochainement le résultat de votre examen.</p><p>Nous vous remercions de votre participation.</p>',
    retryMessage: `Nous somme désolé de vous informer que vous n'avez pas passé cette examen.
Vous avez la possibilité de le refaire encore {attempts} fois.
Nous vous encouragons à revoir la théorie avant de réessayer.`,
    retry: 'Vous avez échoué, veuillez réessayer.',
    correction: 'Correction'
  },
  notifications: {
    saved: 'Sauvé',
    sent: 'Envoyé',
    sessionStarted: 'Cette session a déjà été commencé.'
  },
  newAttendants: {
    file: 'Fichier exemple',
    changeEmail: 'Veuillez changer l\'email',
    addManualy: 'Ajouter manuellement un nouvel étudiant',
    import: 'Importer des étudiants',
    importCSV: 'Importer des étudiants avec un fichier .csv'
  },
  exam: {
    preview: 'Aperçu',
    exams: 'Examens',
    link: 'Lien d\'accès',
    publicLink: 'Lien publique',
    edit: 'Modifier',
    feedback: 'Feedback',
    duplicate: 'Copier',
    notify: 'Email pour recevoir une notification quand l\'examen a été fait par quelqu\'un.',
    diplomaTitle: 'Titre du diplome',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    addAttendant: 'Ajouter des étudiants',
    filter: 'Filtrer',
    stats: '% ont réussit avec une moyenne de {averageScore}% en {averageTime} minutes.',
    editWarning: 'Vous ne pouvez pas modifier l\'examen après que quelqu\'un l\'ait commencé.',
    notStarted: 'Pas commencé',
    sendResults: 'Envoyer les résultats',
    sendDiplomas: 'Envoyer les diplomes',
    diplomaConfirmation: 'Assurez-vous que tous les champs du diplome sont corrects. Êtes-vous sûr qu\'ils sont corrects et que vous voulez envoyer les diplomes ?',
    autoSendResult: 'Envoyer les résultats automatiquement',
    autoSendDiploma: 'Envoyer les diplomes automatiquement',
    deleteConfirmation: 'Voulez-vous vraiment supprimer cet examen et tous les résultats?',
    emailSubject: 'Résultat d\'examen',
    emailScore: 'Votre score à l\'examen _exam est de _score%.',
    emailSuccess: 'Nous vous félicitons pour avoir réussi votre examen. Prochainement, vous recevrez un lien qui vous donnera accès à votre diplôme.',
    emailFailure: 'Nous sommes désolé, vous avez échoué cet examen.'
  },
  editor: {
    editor: 'Éditeur',
    back: 'Retour',
    export: 'Exporter',
    save: 'Enregistrer',
    name: 'Nom',
    time: 'Temps (minutes)',
    language: 'Language',
    maxAttempt: 'Essai(s)',
    negativePoints: 'Points négatifs',
    introduction: 'Introduction',
    successScore: 'Score de réussite',
    questionsToShow: 'Nombre d\'question',
    hideQuestions: 'Ne pas montrer toutes les questions',
    isMCQ: 'Questions à choix multiple',
    email: 'Email du résultat',
    emailSubject: 'Sujet',
    emailScore: 'Score',
    emailSuccess: 'Message de réussite',
    emailFailure: 'Message d\'échec',
    exam: 'Exam',
    remove: 'Supprimer',
    optional: 'Facultatif',
    topic: 'Sujet',
    situation: 'Situation',
    context: 'Contexte',
    image: 'Image',
    notImage: 'Ce n\'est pas une image',
    question: 'Question',
    answer: 'Réponse',
    addAnswer: 'Ajouter une réponse',
    addQuestion: 'Ajouter une question',
    addSituation: 'Ajouter une situation',
    addTopic: 'Ajouter un topic',
    showCorrection: 'Montrer la correction',
    generate: 'Générer',
    generationText: 'Texte source (moins de 1000 mots)'
  },
  results: {
    status: 'Statut',
    duration: 'Durée',
    date: 'Date',
    score: 'Score',
    points: 'Points',
    correct: 'Correct',
    unanswered: 'Non répondu',
    wrong: 'Faux',
    reset: 'Remettre à zero'
  },
  languages: {
    en: 'Anglais',
    fr: 'Français'
  }
}
