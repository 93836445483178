<template lang="html">
  <div
    v-if="result"
    class="noprint"
  >
    <div
      v-if="$route.name === 'Preview'"
      class="portrait p2"
    >
      <h2>{{ $t('exam.preview') }}</h2>
    </div>
    <h2 class="exam-name center">
      {{ session.exam.data.name }}
    </h2>
    <h3
      v-if="showCorrection"
      class="center"
    >
      Score {{ getScore(session.exam, result.answers) }}%
    </h3>
    <div
      v-for="(topic, topicOrder) in topicsIndexes"
      :key="topic.index"
    >
      <h3
        v-if="session.exam.data.topics[topic.index].name || showCorrection"
        class="topic center"
      >
        {{ session.exam.data.topics[topic.index].name }}
        <span v-if="showCorrection">
          {{ getScore(session.exam, result.topics[topic.index].answers) }}%
        </span>
      </h3>
      <section
        v-for="(situation, situationOrder) in topic.situationsIndexes"
        :id="`${topic.index}-${situation.index}`"
        :key="situation.index"
        class="p0"
      >
        <div class="situation-header sticky px2 py1">
          <b>{{ `${topicOrder + 1}.${situationOrder + 1}` }}</b>
          <div>
            <button
              v-if="session.exam.data.topics[topic.index].situations[situation.index].context || session.exam.data.topics[topic.index].situations[situation.index].files?.length"
              @click="context = topic.index + '-' + situation.index"
            >
              {{ $t('session.context') }}
            </button>
            <button
              v-if="session.exam.data.hasNotes"
              @click="notes = topic.index + '-' + situation.index"
            >
              {{ $t('session.notes') }}
            </button>
          </div>
        </div>

        <div class="content">
          <div>
            <div
              v-if="session.exam.data.topics[topic.index].situations[situation.index].context || session.exam.data.topics[topic.index].situations[situation.index].files?.length"
              :class="['theme', context === topic.index + '-' + situation.index ? 'overlay' : 'sidecontent']"
            >
              <ContextView
                v-if="session.exam.data.topics[topic.index].situations[situation.index].context"
                :id="topic.index + '-' + situation.index"
                :context="session.exam.data.topics[topic.index].situations[situation.index].context"
              />
              <img
                v-if="session.exam.data.topics[topic.index].situations[situation.index].files?.length"
                :src="session.exam.data.topics[topic.index].situations[situation.index].files[0].url"
              >
              <button
                v-if="context"
                @click="context = null"
              >
                {{ $t('session.close') }}
              </button>
            </div>
          </div>
          <div>
            <div
              v-for="question in situation.questionsIndexes"
              :key="`${topic.index}-${situation.index}-${question.index}`"
              class="question"
            >
              <div
                :id="`${topic.index}-${situation.index}-${question.index}`"
                class="theme sticky wrap-text"
                v-html="session.exam.data.topics[topic.index].situations[situation.index].questions[question.index].question"
              />
              <div
                v-for="answerIndex in question.answersIndexes"
                :id="result.topics[topic.index].situations[situation.index].questions[question.index].answers[answerIndex] === null ? 'unanswered' : ''"
                :key="answerIndex"
                class="theme pt1 wrap-text"
              >
                {{ session.exam.data.topics[topic.index].situations[situation.index].questions[question.index].answers[answerIndex].answer }}
                <div class="answer">
                  <button
                    v-for="choice in session.exam.data.isMCQ ? [true, false] : [true, null, false]"
                    :key="choice"
                    type="button"
                    :disabled="showCorrection"
                    :style="getAnswerColor(choice, result.topics[topic.index].situations[situation.index].questions[question.index].answers[answerIndex], session.exam.data.topics[topic.index].situations[situation.index].questions[question.index].answers[answerIndex].isTrue)"
                    @click="result.topics[topic.index].situations[situation.index].questions[question.index].answers.splice(answerIndex, 1, choice)"
                  >
                    {{ $t('session.' + choice) }}
                    <span v-if="showCorrection && result.topics[topic.index].situations[situation.index].questions[question.index].answers[answerIndex] !== session.exam.data.topics[topic.index].situations[situation.index].questions[question.index].answers[answerIndex].isTrue">
                      &#8594;{{ $t('session.' + session.exam.data.topics[topic.index].situations[situation.index].questions[question.index].answers[answerIndex].isTrue) }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              v-if="session.exam.data.hasNotes"
              :class="['theme', notes === topic.index + '-' + situation.index ? 'overlay' : 'sidecontent']"
            >
              <div>
                <h4>{{ $t('session.notesDetails') }}</h4>
                <TextInput
                  v-model:text="situation.notes"
                  placeholder="..."
                />
              </div>
              <button
                v-if="notes"
                @click="notes = null"
              >
                {{ $t('session.close') }}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="center">
      <h2 class="end">
        {{ $t('session.end') }}
      </h2>
      <div>
        <button
          v-if="result.totalAnswers !== responses"
          @click="scrollToElement('unanswered')"
        >
          {{ result.totalAnswers - responses }} {{ $tc('session.questions', result.totalAnswers - responses) }} {{ $t('session.unanswered') }}
        </button>
      </div>
      <div v-if="!showCorrection">
        <button
          type="button"
          class="mt2"
          @click="showFinishPopup = true"
        >
          {{ $t('session.finish') }}
        </button>
      </div>
    </div>
    <small class="info">
      <span
        v-if="timeout"
        :style="timeout < 600 ? 'background-color: #f22' : ''"
      >
        {{ $t('session.timeout') }}: {{ formattedTimeout }}
      </span>
      <span v-if="!session.exam.data.isMCQ">
        {{ $t('session.answers') }}: {{ responses }}/{{ result.totalAnswers }}
      </span>
    </small>
    <PopupWindow v-if="showFinishPopup">
      <div v-if="result.totalAnswers !== responses">
        {{ $t('session.notDone') }} {{ result.totalAnswers - responses }} {{ $tc('session.questions', result.totalAnswers - responses) }}.
      </div>
      <div>{{ $t('session.finishConfirmation') }}</div>
      <div class="text-right mt1">
        <button
          type="button"
          @click="showFinishPopup = false"
        >
          {{ $t('session.cancel') }}
        </button>
        <button
          type="button"
          @click="finish"
        >
          {{ $t('session.confirm') }}
        </button>
      </div>
    </PopupWindow>
  </div>
</template>

<script>
import PopupWindow from '@/components/PopupWindow'
import ContextView from '@/components/ContextView'
import { mapState, mapActions } from 'pinia'
import { useExamsStore } from '../stores/exams'
import { getScore } from '@/utils/results'
import { useUIStore } from 'arketiks-tools'

export default {
  name: 'Session',
  components: {
    PopupWindow,
    ContextView
  },
  data () {
    return {
      result: null,
      timeout: 0,
      responses: 0,
      showFinishPopup: false,
      interval: null,
      windowHeight: 0,
      context: null,
      notes: null,
      topicsIndexes: []
    }
  },
  computed: {
    ...mapState(useExamsStore, ['session']),
    formattedTimeout () {
      const s = this.timeout % 60
      const m = Math.floor(this.timeout % 3600 / 60)
      const h = Math.floor(this.timeout / 3600)
      return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`
    },
    showCorrection () {
      return this.session.exam.data.showCorrection && this.result.status === 'done'
    }
  },
  watch: {
    result: {
      deep: true,
      async handler () {
        this.responses = 0
        for (const t of Object.keys(this.result.topics)) {
          for (const s of Object.keys(this.result.topics[t].situations)) {
            for (const i of Object.keys(this.result.topics[t].situations[s].questions)) {
              this.result.topics[t].situations[s].questions[i].answers.forEach(a => {
                if (a !== null) this.responses++
              })
            }
          }
        }
        if (this.$route.name === 'Session') {
          await this.setResult({ id: this.session.result.id, data: this.result })
        }
      }
    }
  },
  async mounted () {
    this.windowHeight = window.innerHeight
    if (this.$route.name === 'Session') {
      document.onselectionchange = () => {
        if (document.getSelection().type === 'Range') {
          document.getSelection().removeAllRanges()
        }
      }
      document.onkeydown = (event) => !event.ctrlKey
      document.oncontextmenu = () => false
      if (!this.session) {
        const credentials = JSON.parse(localStorage.getItem('credentials'))
        if (credentials) await this.getSession(credentials)
        else return this.$router.push({ name: 'Login' })
      }
      if (!this.session.result.data.answers) {
        this.startTimeout()
      } else if (!this.session.exam.data.showCorrection) {
        this.$router.push({ name: 'SessionEnd' })
      }
      this.initResult()
      this.session.result.data = this.result
      this.$nextTick(this.getExamHeigths)
    }
  },
  async activated () {
    if (this.$route.name === 'Preview') {
      await this.getPreview(this.$route.params.examId)
      this.initResult()
      this.session.result.data = this.result
      this.$nextTick(this.getExamHeigths)
    }
  },
  unmounted () {
    document.onselectionchange = null
    document.onkeydown = null
    document.oncontextmenu = null
  },
  methods: {
    ...mapActions(useExamsStore, [
      'setResult',
      'getSession',
      'getPreview'
    ]),
    ...mapActions(useUIStore, [
      'addDangerNotification'
    ]),
    getScore,
    initResult () {
      if (this.session.result.data.topics) {
        this.result = this.session.result.data
      } else {
        const topics = {}
        const topicsOrders = this.getOrders(this.session.exam.data.topics.length)
        let totalAnswers = 0

        topicsOrders.forEach(topicIndex => {
          const topic = this.session.exam.data.topics[topicIndex]
          topics[topicIndex] = {
            situations: {}
          }
          const situationsQuestionsOrders = []
          for (const i in this.session.exam.data.topics[topicIndex].situations) {
            situationsQuestionsOrders.push({
              index: i,
              questionsOrders: this.getOrders(topic.situations[i].questions.length)
            })
          }
          let questionsCounter = 0
          while (this.session.exam.data.hideQuestions ? questionsCounter < topic.questionsToShow : situationsQuestionsOrders.length) {
            const situationQuestionIndex = Math.floor(Math.random() * situationsQuestionsOrders.length)
            const situation = situationsQuestionsOrders[situationQuestionIndex]
            const questionIndex = situation.questionsOrders.pop()
            if (!situation.questionsOrders.length) {
              situationsQuestionsOrders.splice(situationQuestionIndex, 1)
            }
            if (!topics[topicIndex].situations[situation.index]) {
              topics[topicIndex].situations[situation.index] = {
                questions: {},
                notes: ''
              }
            }
            topics[topicIndex].situations[situation.index].questions[questionIndex] = {
              answers: topic.situations[situation.index].questions[questionIndex].answers
                .map(a => {
                  totalAnswers++
                  return this.session.exam.data.isMCQ ? false : null
                })
            }
            questionsCounter++
          }
        })

        this.result = {
          topics,
          totalAnswers,
          status: 'started',
          startTime: Date.now(),
          ...this.session.result.data
        }
      }

      this.topicsIndexes = this.getOrderedItems(this.result.topics).map(t => {
        return {
          index: t.index,
          situationsIndexes: this.getOrderedItems(t.situations).map(s => {
            return {
              index: s.index,
              questionsIndexes: this.getOrderedItems(s.questions).map(i => {
                return {
                  index: i.index,
                  answersIndexes: this.getOrders(i.answers.length)
                }
              })
            }
          })
        }
      })
    },
    getOrders (size) {
      const orders = []
      for (let i = 0; i < size; i++) {
        orders.splice(Math.round(Math.random() * orders.length), 0, i)
      }
      return orders
    },
    getShuffledItems (item) {
      const keys = Object.keys(item)
      const fields = this.getOrders(keys.length).map(i => {
        return {
          index: keys[i],
          ...item[keys[i]]
        }
      })
      return fields
    },
    getOrderedItems (item) {
      const keys = Object.keys(item)
      const fields = []
      for (let i = 0; i < keys.length; i++) {
        fields.push({
          index: keys[i],
          ...item[keys[i]]
        })
      }
      return fields
    },
    startTimeout () {
      if (this.session.exam.data.time > 0) {
        this.timeout = parseInt(localStorage.getItem('timeout'))
        this.interval = setInterval(() => {
          this.timeout--
          localStorage.setItem('timeout', this.timeout)
          if (this.timeout === 0) {
            clearInterval(this.interval)
            this.finish()
          }
        }, 1000)
      }
    },
    async finish () {
      try {
        clearInterval(this.interval)

        const answers = {
          correct: 0,
          wrong: 0,
          unanswered: 0,
          score: 0,
          points: 0,
          total: 0
        }
        for (const t of Object.keys(this.result.topics)) {
          let correct = 0
          let wrong = 0
          let unanswered = 0
          let points = 0
          let total = 0
          for (const s of Object.keys(this.result.topics[t].situations)) {
            for (const i of Object.keys(this.result.topics[t].situations[s].questions)) {
              if (this.session.exam.data.isMCQ) {
                total++
                let correctAnswer = 0
                let wrongAnswer = 0
                let maxPoints = 0
                this.result.topics[t].situations[s].questions[i].answers.forEach((a, anwerIndex) => {
                  if (this.session.exam.data.topics[t].situations[s].questions[i].answers[anwerIndex].isTrue) {
                    maxPoints++
                  }
                  if (a) {
                    if (a === this.session.exam.data.topics[t].situations[s].questions[i].answers[anwerIndex].isTrue) {
                      correctAnswer++
                    } else wrongAnswer++
                  } else if (this.session.exam.data.topics[t].situations[s].questions[i].answers[anwerIndex].isTrue) {
                    unanswered++
                  }
                })
                correct += correctAnswer
                wrong += wrongAnswer
                if (maxPoints) {
                  points += Math.max(0, correctAnswer - wrongAnswer) / maxPoints
                }
              } else {
                this.result.topics[t].situations[s].questions[i].answers.forEach((a, anwerIndex) => {
                  if (a === null) unanswered++
                  else if (a === this.session.exam.data.topics[t].situations[s].questions[i].answers[anwerIndex].isTrue) {
                    correct++
                  } else wrong++
                  total++
                })
              }
            }
          }
          this.result.topics[t].answers = {
            correct,
            wrong,
            unanswered,
            points,
            score: Math.round(points / total * 100),
            total
          }
          answers.correct += correct
          answers.wrong += wrong
          answers.unanswered += unanswered
          answers.points += points
          answers.total += total
        }
        answers.score = Math.round(answers.points / answers.total * 100)
        this.result.answers = answers
        this.result.status = 'done'
        this.result.attempt += 1
        this.result.endTime = Date.now()

        if (this.$route.name === 'Session') {
          this.result.timeLeft = this.timeout
          await this.setResult({ id: this.session.result.id, data: this.result })
          localStorage.clear()
        }
        this.showFinishPopup = false
        this.$router.push({ name: this.$route.name === 'Session' ? 'SessionEnd' : 'Exam' })
      } catch (e) {
        this.addDangerNotification(e.message)
      }
    },
    getElementHeight (id) {
      const element = document.getElementById(id)
      return element ? element.clientHeight : 0
    },
    scrollToElement (id) {
      const element = document.getElementById(id)
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      })
    },
    getAnswerColor (type, userAnswer, correctAnswer) {
      if (userAnswer === type) {
        if (this.showCorrection) {
          return userAnswer === correctAnswer ? 'background-color: #9f9' : 'background-color: #f99'
        }
        return 'background-color: #8cf'
      }
      return this.showCorrection ? 'display:none' : 'background-color: #ddd'
    }
  }
}
</script>

<style lang="css" scoped>
.exam-name {
  margin: 3rem 0;
  background: none;
}

.topic {
  background-color: var(--color-background-secondary);
  margin: 0;
  padding: 1rem;
}

.info {
  position: sticky;
  bottom: 0;
  display: flex;
  font-weight: bold;
  z-index: 2;
  margin-top: 4rem;
}
.info > *:first-child {
  margin-right: auto;
}
.info > * {
  background-color: var(--color-background-soft);
  border-radius: 1px;
  padding-left: .5rem;
  padding-right: .25rem;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.index {
  padding: 0 .5rem;
  margin-right: .5rem;
}

.sticky {
  position: sticky;
  top: 0;
  box-shadow: 0 5px 10px var(--color-background);
  margin-top: .25em;
}

.center {
  text-align: center;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1em 0;
}

.overlay {
  position: fixed;
  top: .25rem;
  left: 0;
  right: 0;
  max-height: 99vh;
  margin: auto;
  background-color: var(--color-background);
  z-index: 10;
  box-shadow: 0 0 3px #8888;
  overflow-y: auto;
}
.overlay > div {
  padding: 1rem;
}
.overlay > button {
  position: absolute;
  top: .75rem;
  right: 1rem;
  margin-top: .25rem;
  padding: 0 .5rem;
  z-index: 10;
}

.situation-header {
  z-index: 2;
  display: flex;
  align-items: center;
  background-color: var(--color-background);
  border-radius: 1px;
  margin-top: 0;
  box-shadow: 0 5px 8px var(--color-background);
}
.situation-header > div {
  flex: auto;
  text-align: right;
}
.situation-header button {
  margin: .25rem;
  border: none;
}

.sidecontent {
  position: fixed;
  top: -200vh;
  max-height: 99vh;
}

h4 {
  margin-top: 0rem;
}

.wrap-text {
  white-space: pre-line;
}

.question {
  padding: 0 1rem;
}
.question:not(:last-child) {
  border-bottom: 1px solid var(--color-background-secondary);
  padding-bottom: 1rem;
}
.question > div:first-child {
  padding: 1em 0;
  margin-top: -.5em;
  margin-bottom: .5em;
  border-bottom: 1px solid #8888;
  top: 1.5em;
  max-height: 50vh;
  overflow: auto;
}

.answer {
  display: flex;
  margin: .5rem 0;
}
.answer > * {
  flex: 1;
  border: 1px solid var(--color-background-secondary);
  color: #000;
  margin: 0;
  border-radius: 0;
  padding: .5rem;
}
.end {
  background-color: var(--color-background-secondary);
  padding: 1rem;
}

img {
  width: 100%
}

@media (min-width:1200px) {
  .content {
    flex-direction: row;
    width: 100%;
  }
  .content > div {
    flex: 1;
  }

  .sidecontent {
    position: sticky;
    display: block;
    top: 2.5em;
    margin: .5em 1em;
    box-shadow: 0 0 3px #8888;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 5rem);
  }
  .sidecontent > div {
    padding: 1rem;
  }

  .situation-header button {
    display: none;
  }
}

@media print {
  .noprint {
    display: none;
  }
}
</style>
