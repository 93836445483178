import { createRouter, createWebHistory } from 'vue-router'
import { useAppStore } from '../stores/app'

import { TheOrganizations } from 'arketiks-tools'
import Session from '../views/Session.vue'
import SessionEnd from '../views/SessionEnd.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/unauthorized',
    name: 'noOrg',
    component: () => import(/* webpackChunkName: "noOrg" */ '../views/NoOrg.vue')
  },
  {
    path: '/orgs',
    name: 'organizations',
    component: TheOrganizations,
    props: () => ({ orgs: useAppStore().orgs }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:org/login/:examId',
    name: 'OrgLogin',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/:org',
    name: 'Exams',
    component: () => import(/* webpackChunkName: "exams" */ '../views/Exams.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:org/admins',
    name: 'Admins',
    component: () => import(/* webpackChunkName: "admins" */ '../views/Admins.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:org/exam/:examId',
    name: 'Exam',
    component: () => import(/* webpackChunkName: "exam" */ '../views/Exam.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:org/result/:resultId',
    name: 'Result',
    component: () => import(/* webpackChunkName: "result" */ '../views/Result.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:org/editor/:examId',
    name: 'Editor',
    component: () => import(/* webpackChunkName: "editor" */ '../views/Editor.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:org/feedback/:examId',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "feedback" */ '../views/Feedback.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:org/preview/:examId',
    name: 'Preview',
    component: () => import(/* webpackChunkName: "preview" */ '../views/Session.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:org/sessionStart',
    name: 'SessionStart',
    component: () => import(/* webpackChunkName: "sessionStart" */ '../views/SessionStart.vue')
  },
  {
    path: '/:org/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/:org/sessionEnd',
    name: 'SessionEnd',
    component: SessionEnd
  },
  {
    path: '/*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (useAppStore().user) next()
    else {
      next({ name: 'Login' })
    }
  } else next()
})

export default router
