import './firebase'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import translations from './i18n'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import TextInput from './components/TextInput'

import 'goku-css'
import 'arketiks-tools/dist/style.css'
import './css/index.css'

const i18n = createI18n(translations)
const app = createApp(App)
app
  .use(createPinia())
  .use(router)
  .use(i18n)
  .component('TextInput', TextInput)
  .mount('#app')

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://bb05bc9de4e348fa910e0157e65ec11f@o117014.ingest.sentry.io/5340807',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    maxValueLength: 5000,
    tracesSampleRate: 0.1
  })
}
