<template>
  <textarea
    v-model="editedText"
    rows="1"
    :required="isRequired"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    text: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:text'
  ],
  computed: {
    editedText: {
      get () {
        return this.text
      },
      set (value) {
        this.$emit('update:text', value)
      }
    }
  },
  watch: {
    text () {
      this.resize()
    }
  },
  mounted () {
    this.resize()
  },
  methods: {
    resize () {
      this.$nextTick(() => {
        const textarea = this.$el
        textarea.style.cssText = 'height:auto'
        textarea.style.cssText = `height:${textarea.scrollHeight}px`
      })
    }
  }
}
</script>

<style scoped>
textarea {
  overflow: hidden;
  width: 100%;
}
</style>
